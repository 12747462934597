<template>
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
</template>
<script setup lang="ts">
const route = useRoute();
</script>
<style lang="scss">
@import "./assets/main.scss";
</style>
