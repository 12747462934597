import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as _91id_93SZ3w80CUY3Meta } from "/app/pages/keys-and-values/[id].vue?macro=true";
import { default as newOboJYGYNC9Meta } from "/app/pages/keys-and-values/new.vue?macro=true";
import { default as keys_45and_45valueslmwySsxV6uMeta } from "/app/pages/keys-and-values.vue?macro=true";
import { default as sign_45inMH4whhaZtqMeta } from "/app/pages/sign-in.vue?macro=true";
export default [
  {
    name: indexN6pT4Un8hYMeta?.name ?? "index",
    path: indexN6pT4Un8hYMeta?.path ?? "/",
    meta: indexN6pT4Un8hYMeta || {},
    alias: indexN6pT4Un8hYMeta?.alias || [],
    redirect: indexN6pT4Un8hYMeta?.redirect || undefined,
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: keys_45and_45valueslmwySsxV6uMeta?.name ?? "keys-and-values",
    path: keys_45and_45valueslmwySsxV6uMeta?.path ?? "/keys-and-values",
    children: [
  {
    name: _91id_93SZ3w80CUY3Meta?.name ?? "keys-and-values-id",
    path: _91id_93SZ3w80CUY3Meta?.path ?? ":id()",
    meta: _91id_93SZ3w80CUY3Meta || {},
    alias: _91id_93SZ3w80CUY3Meta?.alias || [],
    redirect: _91id_93SZ3w80CUY3Meta?.redirect || undefined,
    component: () => import("/app/pages/keys-and-values/[id].vue").then(m => m.default || m)
  },
  {
    name: newOboJYGYNC9Meta?.name ?? "keys-and-values-new",
    path: newOboJYGYNC9Meta?.path ?? "new",
    meta: newOboJYGYNC9Meta || {},
    alias: newOboJYGYNC9Meta?.alias || [],
    redirect: newOboJYGYNC9Meta?.redirect || undefined,
    component: () => import("/app/pages/keys-and-values/new.vue").then(m => m.default || m)
  }
],
    meta: keys_45and_45valueslmwySsxV6uMeta || {},
    alias: keys_45and_45valueslmwySsxV6uMeta?.alias || [],
    redirect: keys_45and_45valueslmwySsxV6uMeta?.redirect || undefined,
    component: () => import("/app/pages/keys-and-values.vue").then(m => m.default || m)
  },
  {
    name: sign_45inMH4whhaZtqMeta?.name ?? "sign-in",
    path: sign_45inMH4whhaZtqMeta?.path ?? "/sign-in",
    meta: sign_45inMH4whhaZtqMeta || {},
    alias: sign_45inMH4whhaZtqMeta?.alias || [],
    redirect: sign_45inMH4whhaZtqMeta?.redirect || undefined,
    component: () => import("/app/pages/sign-in.vue").then(m => m.default || m)
  }
]